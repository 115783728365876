body,
html {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.auth-page {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to bottom right, rgba(255, 239, 255, 0.664), rgb(238, 238, 238), rgba(255, 239, 255, 0.658));

}

.large-size{
  font-size: large;
}

.input-field-style {
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  border-radius: 5px;
  height: 48px;
  padding: 0 2px;
  background-color: #fff;
}

.input-field-style>input {
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  padding: 2px 4px;
  border-radius: 3px;
}

.hover {
  cursor: pointer;
}

.selected-items {
  border: 1px solid #6F2DA8;
  border-radius: 10px;
  padding: 10px 15px;

}

.selected-item {
  border: 1px solid #DADAE7;
  border-radius: 5px;
  padding: 4px 4px;
  margin-top: 3px;
}

.section {
  margin-top: 10rem;
  margin-bottom: 20px !important;
}

.header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-between;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.padding-top {
  padding-top: 30px;
}

.big-div {
  font-size: 2em;
}

.black.bold:hover {
  background-color: #9c27b0 !important;
  color: white !important;
}

.giff,
.gif {
  border: 3px solid #fff;
  box-sizing: border-box;
}

p {
  margin: 0;
}

.full-screen {
  background-color: #39204f;
  margin: 0;
  height: 100vh;
  overflow: auto;
}

.container {
  display: flex;
  height: 100vh;
}

.image-container {
  flex: 2;
}

.image-container img {
  margin-top: 20vh;
  margin-left: 20%;
  padding-left: 0;
  width: 45%;
  height: 60%;
}

.content-container {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.options button {
  padding: 10px;
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

.options button:hover {
  background-color: #6f2da8;
  color: white;
}

.submit-button {
  margin-top: 20px;
}

.submit-button button {
  padding: 15px 30px 15px 30px;
  background-color: #6f2da8;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50px;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.icon {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}

.icon-active {
  background-color: #6F2DA8;
}

.icon-active,
.icon-circle,
.icon-current {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #E0E0E0;
  margin: 0 10px;
}

.icon-current {
  border: 2px solid #6F2DA8;
}

.order-number {
  background-color: #6F2DA8;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-question {
  margin-left: 10px;
}

.radio-buttons {
  display: flex;
  gap: 10px;
}

.quantity-field {
  margin-top: 10px;
}

.next-button {
  background-color: #B3B3B3;
  border: transparent;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  color: white;
}

.input-field {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 3px 5px;
  background-color: #fff;
  border: 1px solid #D1D1D1;
  width: 10vw;
  height: 40px;
  margin-left: 40px;
}

.input-field>input {
  width: 100%;
  border: none;
  outline: none;
}

.custom-radio-button:checked {
  background-color: grey;
}

.main-page {
  max-height: 100vh;
  overflow-y: auto;
}

.background-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: bottom center;
  margin: 0;
  padding: 0;
}

.bg {
  position: absolute;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: bottom center;
  bottom: 0;
  margin: 0;
  padding: 0;
}

.overlays {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
}

.icon-image {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
}

 .hide-content {
  opacity: 0;
  pointer-events: none;
}

.show-content {
  opacity: 1;
}

.cards-heading {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.cards-container1 {
  display: flex;
  justify-content: space-evenly;
  gap: 5rem;
  padding-left: 10vh;
}

.material-symbols-outlined {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
}

.App {
  position: relative;
  width: 100%;
  height: 100vh;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.top-right-corner {
  position: absolute;
  top: 5%;
  right: 5%; 
  cursor: pointer;
}

.imgStyle {
  width: 27px;
  height: 25px;
  padding: 3px;
  border-radius: 5px;
}

.modal-content {
  position: relative;
  z-index: 1;
  padding: 30px; 

  @media (max-width: 480px) {
    padding: 10px;
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    padding: 20px;
  }
}

.modal-close {
  font-size: 20px; 
  top: 10px;
  right: 10px;

  @media (max-width: 480px) {
    fontSize: 15px;
    top: 5px;
    right: 5px;
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    fontSize: 20px;
  }
}

.input-field {
  width: 20vw;

  @media (max-width: 480px) {
    width: 70vw;
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    width: 40vw;
  }
}

.btns {
  width: 10vw;

  @media (max-width: 480px) {
    width: 30vw; 
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    width: 14vw; 
  }
}

h1 {
  color: #263238;

  font-size: 36px;
  
  @media (max-width: 480px) {
    font-size: 24px;
  }

  @media (min-width: 481px) and (max-width: 1024px) { 
    font-size: 30px;
  }
}

h2 {
  color: #455A64;

  font-size: 26px; 

  @media (max-width: 480px) { 
    font-size: 18px;
  }

  @media (min-width: 481px) and (max-width: 1024px) { 
    font-size: 22px;
  }
}

.logo {
  margin-right: 80%; 

  @media (max-width: 480px) { 
    margin-right: 0%;
  }

  @media (min-width: 481px) and (max-width: 1024px) { 
    margin-right: 70%;
  }
}

.header {
  padding: 1rem;
}

.mt-2 {
  margin-top: 2rem;
  flex-shrink: 0;
}

.bold.hover {
  cursor: pointer;
  color: #6F2DA8;
  padding-left: 30px;
  font-size: 20px;
}

.flex.columns {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.icon-current img, .icon-active img, .icon-circle img {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}

.mt-2.center {
  margin-top: 2rem;
  text-align: center;
  font-size: 24px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .bold.hover {
      padding-left: 1px;
      font-size: 8px;
  }

  .icon-current, .icon-active, .icon-circle {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1px;  
}

.icon-current img, .icon-active img, .icon-circle img {
    width: 50%; 
    height: auto;
}

  .mt-2.center {
      font-size: 20px;
  }
}
.btn-outline {
  width: 6rem;
}

@media (max-width: 768px) {
  .btn-outline {
    width: 5rem;
    font-size: 12px;
  }

  .input-field {
    width: 70%;
  }
}

@media (max-width: 768px) {
 .flex.gap-2 {
    gap: 1.5rem;
  }
}

